import { useEffect, useState } from 'react';
import {
  createBanner,
  deleteBanner,
  getAllBanners,
  getHomePageBanners,
  getMediaFile,
  updateBanner
} from '../../core/api';
import { BannerType, BannerViewModel } from '../../core/backend/models';
import { PhotoCamera } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Alert,
  Box,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import './HomePageCms.scss';
import HeroBannerDefaultImage from '../../images/hero_banner_section.png';
import BannerSecondaryDefaultImage from '../../images/secondary_banner_section.png';
import BannerSecondaryTwoDefaultImage from '../../images/secondary_banner_2_section.png';
import Button from '@mui/material/Button';
import Loader from '../../components/Loader/Loader';
import BaseModal from '../../components/Modals/BaseModal/BaseModal';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import { isValidURL } from '../../core/helpers/helpers';
import appToast from '../../core/toast';

const Input = styled('input')({
  display: 'none'
});

interface HeroBannerFormValues {
  image: File;
}

interface SecondaryBannersFormValues {
  image: File;
  link: string;
}

const HomePageCms = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [deleteModal, setDeleteModal] = useState<{
    id: number | null;
    isOpen: boolean;
  }>({ id: null, isOpen: false });
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [banners, setBanners] = useState<BannerViewModel[]>([]);
  const [secBanners, setSecBanners] = useState<BannerViewModel[]>(null);

  const [compareSortData, setCompareSortData] = useState<
    { id: number; order: number }[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [heroImageSrc, setHeroImageSrc] = useState('');
  const [firstBannerImageSrc, setFirstBannerImageSrc] = useState('');
  const [secondaryBannerImageSrc, setSecondaryBannerImageSrc] = useState('');

  const isSmallScreen = useMediaQuery('(max-width:972px)');

  const validateSecondaryBanners = (
    values: SecondaryBannersFormValues,
    order: number
  ): FormikErrors<SecondaryBannersFormValues> => {
    const errors: FormikErrors<SecondaryBannersFormValues> = {};

    if (
      !values.image &&
      (!secBanners || !secBanners.some((x) => x.order === order))
    ) {
      errors.image = 'Please select an image first.';
    }

    if (values.link && !isValidURL(values.link)) {
      errors.link = 'Please enter a valid link.';
    }

    return errors;
  };

  const heroFormik = useFormik<HeroBannerFormValues>({
    initialValues: {
      image: null
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors: FormikErrors<HeroBannerFormValues> = {};
      if (!values.image) {
        errors.image = 'Please select an image.';
      }
      return errors;
    },
    onSubmit: async (values: HeroBannerFormValues, { resetForm }) => {
      // For existing hero banner:
      if (banners.some((b) => b.bannerType === BannerType.NUMBER_5)) {
        const heroBannerId = banners.find(
          (b) => b.bannerType === BannerType.NUMBER_5
        )?.id;
        await handleUpdateHomeHeroBanner(heroBannerId, values);
      } else {
        // For new hero banner:
        try {
          setIsProcessing(true);
          await createBanner(values.image, '', BannerType.NUMBER_5);
          appToast.showSuccess('The hero banner was created!');
        } catch (e: any) {
          setErrorMsg(e.response.data.description);
          setErrorModalVisible(true);
        } finally {
          setIsProcessing(false);
        }
      }
    }
  });

  const firstBannerFormik = useFormik<SecondaryBannersFormValues>({
    initialValues: {
      image: null,
      link: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => validateSecondaryBanners(values, 0),
    onSubmit: async (
      values: SecondaryBannersFormValues,
      { resetForm, setErrors }
    ) => {
      setErrors({});

      if (secBanners && secBanners.some((x) => x.order === 0)) {
        const bannerId = secBanners.find((x) => x.order === 0).id;
        await handleUpdateHomePageSecondaryBanner(bannerId, values, 0);
      } else {
        await handleCreateHomePageSecondaryBanner(values, 0);
      }
    }
  });

  const secondBannerFormik = useFormik<SecondaryBannersFormValues>({
    initialValues: {
      image: null,
      link: ''
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => validateSecondaryBanners(values, 1),
    onSubmit: async (
      values: SecondaryBannersFormValues,
      { resetForm, setErrors }
    ) => {
      setErrors({});

      if (secBanners.some((x) => x.order === 1)) {
        const bannerId = secBanners.find((x) => x.order === 1).id;
        await handleUpdateHomePageSecondaryBanner(bannerId, values, 1);
      } else {
        await handleCreateHomePageSecondaryBanner(values, 1);
      }
    }
  });

  /**
   * Request content
   */
  useEffect(() => {
    getContent().then(() => setIsLoading(false));
  }, []);

  const handleCreateHomePageSecondaryBanner = async (
    values: SecondaryBannersFormValues,
    order: number
  ) => {
    try {
      setIsProcessing(true);
      const newBanner = await createBanner(
        values.image,
        values.link,
        BannerType.NUMBER_6,
        null,
        null,
        null,
        order
      );
      appToast.showSuccess('The banner was created!');
    } catch (e: any) {
      setErrorMsg(e.response.data.description);
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdateHomePageSecondaryBanner = async (
    bannerId: number,
    values: SecondaryBannersFormValues,
    order: number
  ) => {
    try {
      setIsProcessing(true);
      await updateBanner(
        bannerId,
        values.link,
        values.image,
        BannerType.NUMBER_6,
        null,
        null,
        null,
        order
      );
      appToast.showSuccess('The banner was updated!');
    } catch (e: any) {
      setErrorMsg(e.response.data.description);
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdateHomeHeroBanner = async (
    bannerId: number,
    values: HeroBannerFormValues
  ) => {
    try {
      setIsProcessing(true);
      await updateBanner(bannerId, '', values.image, BannerType.NUMBER_5);
      appToast.showSuccess('The hero banner was updated!');
    } catch (e: any) {
      setErrorMsg(e.response.data.description);
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const getContent = async () => {
    let _banners = await getHomePageBanners();
    if (_banners.length === 0) return;

    setBanners(_banners);

    // It loads the hero banner:
    if (_banners.some((b) => b.bannerType === BannerType.NUMBER_5)) {
      setHeroImageSrc(
        getMediaFile(
          _banners.find((b) => b.bannerType === BannerType.NUMBER_5)?.mediaFile
            .awsUrl
        )
      );
    }

    // It loads the two only secondary banners:
    if (_banners.some((b) => b.bannerType === BannerType.NUMBER_6)) {
      setSecBanners(
        _banners
          .filter((b) => b.bannerType === BannerType.NUMBER_6)
          .sort((a, b) => a.order - b.order)
      );

      const _secondaryBanners = _banners
        .filter((b) => b.bannerType === BannerType.NUMBER_6)
        .sort((a, b) => a.order - b.order);
      setFirstBannerImageSrc(
        _secondaryBanners[0]?.mediaFile.awsUrl
          ? getMediaFile(_secondaryBanners[0]?.mediaFile.awsUrl)
          : ''
      );
      setSecondaryBannerImageSrc(
        _secondaryBanners[1]?.mediaFile.awsUrl
          ? getMediaFile(_secondaryBanners[1]?.mediaFile.awsUrl)
          : ''
      );

      firstBannerFormik.setFieldValue('link', _secondaryBanners[0]?.link ?? '');
      secondBannerFormik.setFieldValue(
        'link',
        _secondaryBanners[1]?.link ?? ''
      );
    }
  };

  const handleDeleteBanner = async () => {
    try {
      setIsProcessing(true);
      await deleteBanner(deleteModal.id);
    } catch (e: any) {
      setErrorModalVisible(true);
    } finally {
      setIsProcessing(false);
      handleCloseDeleteModal();
    }
  };

  const deleteSelectedBanners = async () => {
    const _deleteBanners = selectedItems.map((id) => deleteBanner(id));
    Promise.all(_deleteBanners);

    const _newBanners = banners.filter(
      (banner) => !selectedItems.includes(banner.id)
    );

    setBanners(_newBanners);
    setSelectedItems([]);

    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal({ id: null, isOpen: false });
  };

  const handleCloseErrorModal = () => {
    setErrorMsg('');
    setErrorModalVisible(false);
  };

  const handleHeroBannerImageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newSrc = e.target?.result as string;
        setHeroImageSrc(newSrc);
      };

      heroFormik.setFieldValue('image', e.currentTarget.files[0]);
      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  };

  const handleFirstBannerImageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newSrc = e.target?.result as string;
        setFirstBannerImageSrc(newSrc);
      };

      firstBannerFormik.setFieldValue('image', e.currentTarget.files[0]);
      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  };

  const handleSecondBannerImageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newSrc = e.target?.result as string;
        setSecondaryBannerImageSrc(newSrc);
      };

      secondBannerFormik.setFieldValue('image', e.currentTarget.files[0]);
      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='HomePageCms'>
        {/*       Hero Banner      */}
        <Box sx={{ marginTop: '40px' }}>
          <Typography
            variant='h4'
            gutterBottom
            sx={{ letterSpacing: '-0.05em' }}
            color='secondary'
          >
            Hero Banner
          </Typography>

          {/* Image information for user */}
          <Paper
            className='HomePageCms__heroBanner__alertContainer'
            elevation={4}
          >
            <Alert
              icon={<InfoOutlinedIcon fontSize='small' />}
              severity='info'
              className='HomePageCms__heroBanner__alertContent'
            >
              Proportions for Hero Banner image: 1220 x 370 pixels (Aspect
              Ratio: ~3.297:1).
            </Alert>
          </Paper>

          <Box
            component='form'
            encType='multipart/form-data'
            autoComplete='off'
            onSubmit={heroFormik.handleSubmit}
          >
            <div className='HomePageCms__heroBanner__container'>
              <div className='HomePageCms__heroBanner__loader'>
                {heroImageSrc && (
                  <img
                    className='HomePageCms__heroBanner__image'
                    src={heroImageSrc}
                  />
                )}
                <img
                  src={HeroBannerDefaultImage}
                  className='HomePageCms__heroBanner__defaultImage'
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    width: '100%'
                  }}
                  className='container'
                >
                  <label htmlFor='contained-button-file-banner-hero'>
                    <Input
                      accept='image/*'
                      id='contained-button-file-banner-hero'
                      name='image'
                      type='file'
                      onChange={handleHeroBannerImageChange}
                    />
                    <Button
                      variant='outlined'
                      component='span'
                      color='secondary'
                      sx={{ marginTop: '20px' }}
                      startIcon={<PhotoCamera />}
                    >
                      Replace image
                    </Button>
                  </label>

                  {heroFormik.values.image && (
                    <Button
                      variant='contained'
                      color='secondary'
                      sx={{ marginTop: '20px', maxWidth: '200px' }}
                      type='submit'
                    >
                      Save hero banner
                    </Button>
                  )}
                </div>
              </div>
              {heroFormik.errors?.image && (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.error.main,
                    paddingTop: '18px'
                  }}
                >
                  {heroFormik.errors?.image}
                </Typography>
              )}
            </div>
          </Box>
        </Box>

        {/*       Banner 1      */}
        <Box sx={{ marginTop: '40px' }}>
          <Typography
            variant='h4'
            gutterBottom
            sx={{ letterSpacing: '-0.05em' }}
            color='secondary'
          >
            Banner 1
          </Typography>

          {/* Image information for user */}
          <Paper
            className='HomePageCms__secondaryBanners__alertContainer'
            elevation={4}
          >
            <Alert
              icon={<InfoOutlinedIcon fontSize='small' />}
              severity='info'
              className='HomePageCms__secondaryBanners__alertContent'
            >
              Proportions for Banner 1 image: 1096 x 252 pixels (Aspect Ratio:
              ~4.349:1).
            </Alert>
          </Paper>

          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={firstBannerFormik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  minWidth: '600px',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: isSmallScreen ? '100% !important' : '66.66%',
                  flexBasis: isSmallScreen ? '100% !important' : '66.66%'
                }}
              >
                {firstBannerImageSrc && (
                  <img
                    className='HomePageCms__secondaryBanners__image'
                    src={firstBannerImageSrc}
                  />
                )}

                <img
                  src={BannerSecondaryDefaultImage}
                  className='HomePageCms__secondaryBanners__defaultImage'
                />

                <label htmlFor='contained-button-file-first-banner'>
                  <Input
                    accept='image/*'
                    id='contained-button-file-first-banner'
                    type='file'
                    onChange={handleFirstBannerImageChange}
                  />
                  <Button
                    variant='outlined'
                    component='span'
                    color='secondary'
                    sx={{ marginTop: '20px' }}
                    startIcon={<PhotoCamera />}
                  >
                    Replace image
                  </Button>
                </label>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  '& .MuiGrid-item': {
                    paddingX: '12px'
                  }
                }}
              >
                <Typography
                  variant='h6'
                  sx={{ color: 'dimgray', marginBottom: '12px' }}
                >
                  Add a URL link for the banner
                </Typography>
                <TextField
                  id='link_1'
                  name='link'
                  sx={{ width: '100%', marginBottom: '20px' }}
                  label='(Optional)'
                  color='secondary'
                  value={firstBannerFormik.values.link}
                  onChange={firstBannerFormik.handleChange}
                  error={!!firstBannerFormik.errors?.link}
                  helperText={firstBannerFormik.errors?.link ?? ''}
                />
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{ marginTop: '20px', maxWidth: '200px' }}
                  type='submit'
                >
                  Save banner 1
                </Button>
                {firstBannerFormik.errors?.image && (
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.error.main,
                      paddingTop: '18px'
                    }}
                  >
                    {firstBannerFormik.errors?.image}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/*       Banner 2      */}
        <Box sx={{ marginTop: '40px' }}>
          <Typography
            variant='h4'
            gutterBottom
            sx={{ letterSpacing: '-0.05em' }}
            color='secondary'
          >
            Banner 2
          </Typography>

          {/* Image information for user */}
          <Paper
            className='HomePageCms__secondaryBanners__alertContainer'
            elevation={4}
          >
            <Alert
              icon={<InfoOutlinedIcon fontSize='small' />}
              severity='info'
              className='HomePageCms__secondaryBanners__alertContent'
            >
              Proportions for Banner 2 image: 1096 x 252 pixels (Aspect Ratio:
              ~4.349:1).
            </Alert>
          </Paper>

          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={secondBannerFormik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  minWidth: '600px',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: isSmallScreen ? '100% !important' : '66.66%',
                  flexBasis: isSmallScreen ? '100% !important' : '66.66%'
                }}
              >
                {secondaryBannerImageSrc && (
                  <img
                    className='HomePageCms__secondaryBanners__image'
                    src={secondaryBannerImageSrc}
                  />
                )}

                <img
                  src={BannerSecondaryTwoDefaultImage}
                  className='HomePageCms__secondaryBanners__defaultImage'
                />

                <label htmlFor='contained-button-file-second-banner'>
                  <Input
                    accept='image/*'
                    id='contained-button-file-second-banner'
                    type='file'
                    onChange={handleSecondBannerImageChange}
                  />
                  <Button
                    variant='outlined'
                    component='span'
                    color='secondary'
                    sx={{ marginTop: '20px' }}
                    startIcon={<PhotoCamera />}
                  >
                    Replace image
                  </Button>
                </label>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  '& .MuiGrid-item': {
                    paddingX: '12px'
                  }
                }}
              >
                <Typography
                  variant='h6'
                  sx={{ color: 'dimgray', marginBottom: '12px' }}
                >
                  Add a URL link for the banner
                </Typography>
                <TextField
                  id='link_2'
                  name='link'
                  sx={{ width: '100%', marginBottom: '20px' }}
                  label='(Optional)'
                  color='secondary'
                  value={secondBannerFormik.values.link}
                  onChange={secondBannerFormik.handleChange}
                  error={!!secondBannerFormik.errors?.link}
                  helperText={secondBannerFormik.errors?.link ?? ''}
                />
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{ marginTop: '20px', maxWidth: '200px' }}
                  type='submit'
                >
                  Save banner 2
                </Button>

                {secondBannerFormik.errors?.image && (
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.error.main,
                      paddingTop: '18px'
                    }}
                  >
                    {secondBannerFormik.errors?.image}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>

      <BaseModal
        title='Delete Banner'
        isOpen={deleteModal.isOpen}
        isProcessing={isProcessing}
        onAccept={deleteModal.id ? handleDeleteBanner : deleteSelectedBanners}
        onClose={handleCloseDeleteModal}
      >
        Are you sure you want to delete the banner(s)? This action can not be
        undone.
      </BaseModal>

      <ErrorModal
        isOpen={errorModalVisible}
        cancelButtonVisible={false}
        acceptButtonTitle='Close'
        message={errorMsg}
        onAccept={handleCloseErrorModal}
      />
    </>
  );
};

export default HomePageCms;
