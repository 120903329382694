import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  BannerViewModel,
  BookingStatus,
  BookingViewModel,
  CategoryViewModel,
  ExperienceLogViewModel,
  ExperienceLogViewModelV2,
  FileCarouselItemViewModel,
  FileResponseViewModel,
  GetAllEventsOnlyMediaFile,
  MediaFileReportViewModel,
  UserViewModel,
  VideoStatusType
} from '../backend/models';
import { getElapsedHours, getRemainingHours } from './date-helpers';
import {
  IBookingReportRow,
  IBookingRow,
  IExperienceLogRow,
  INewContentRow,
  IUserRow,
  IVideoReportRow,
  SearchModalData,
  IStatsRow,
  IGeneralStats,
  IAccountStats,
  FilterOptionType
} from '../types';
import {
  translateBookingStatus,
  translateVideoRejectionReason
} from './enums-helpers';
import { SERVICE_FEE } from '../consts';
import { getMediaFile } from '../api';

dayjs.extend(relativeTime);

export const createLightBookingModel = (
  booking: BookingViewModel
): IBookingRow => {
  const _requestDate = new Date(booking.createdOn);
  const _eventDate = new Date(booking.timestampUtc * 1000);
  const requestDate = dayjs(_requestDate);
  const eventDate = dayjs(_eventDate);

  const elapsedHours = getElapsedHours(_requestDate, new Date());
  const remainingHours = getRemainingHours(_eventDate, new Date());
  const hoursUntilEvent = getRemainingHours(_eventDate, _requestDate);

  const responseDeadlineHours =
    hoursUntilEvent >= 0 && hoursUntilEvent <= 24
      ? Math.floor(hoursUntilEvent / 2)
      : hoursUntilEvent > 24 && hoursUntilEvent <= 48
      ? 12
      : hoursUntilEvent > 48 && hoursUntilEvent <= 72
      ? 24
      : hoursUntilEvent > 72 && hoursUntilEvent <= 96
      ? 36
      : 48;

  const expectedEndTime = eventDate.add(booking.duration, 'millisecond');
  const currentTime = new Date().getTime();

  const status =
    (booking.bookingStatus === 0 || booking.bookingStatus === 4) &&
    remainingHours > 0
      ? 'pending'
      : booking.bookingStatus === 2 && remainingHours > 0
      ? 'confirmed'
      : booking.bookingStatus === 2 &&
        remainingHours === 0 &&
        currentTime < expectedEndTime.toDate().getTime()
      ? 'in progress'
      : booking.bookingStatus === 7
      ? 'declined'
      : 'overdue';

  const statusDescriptor =
    booking.bookingStatus === 0
      ? 'Creator Confirmation Needed'
      : booking.bookingStatus === 4 && remainingHours > 0
      ? 'Customer Confirmation Needed'
      : '';

  return {
    ...booking,
    requestTime: booking.createdOn,
    formattedRequestTimeDate: requestDate.format('MM/DD/YY'),
    formattedRequestTime: requestDate.format('hh:mm a'),
    eventTime: booking.timestampUtc * 1000,
    formattedEventTimeDate: eventDate.format('MM/DD/YY'),
    formattedEventTime: eventDate.format('hh:mm a'),
    estimatedResponseTime: requestDate
      .add(responseDeadlineHours, 'hour')
      .toDate()
      .getTime(),
    formattedEstimatedDeadlineDate: requestDate
      .add(responseDeadlineHours, 'hour')
      .format('MM/DD/YY'),
    formattedEstimatedDeadlineTime: requestDate
      .add(responseDeadlineHours, 'hour')
      .format('hh:mm a'),
    elapsedTime: elapsedHours,
    remainingTime: remainingHours,
    hoursUntilEvent,
    formattedRemainingTime: `${remainingHours} hrs`,
    formattedElapsedTime: `${elapsedHours} hrs`,
    status,
    statusDescriptor
  };
};

export const createExtendedExperienceLogModel = (
  log: ExperienceLogViewModel
): IExperienceLogRow => {
  const transaction = dayjs(new Date(log.dateOfTransaction));
  const formattedTransactionDate = transaction.format('MM/DD/YY');
  const formattedTransactionTime = transaction.format('hh:mm a');
  const formattedStatus = translateBookingStatus(log.bookingStatus);

  return {
    ...log,
    formattedTransactionDate,
    formattedTransactionTime,
    formattedStatus
  };
};

export const createVideoModel = (
  video: FileResponseViewModel
): INewContentRow => {
  const dateAdded = dayjs(new Date(video.createdOn));
  const actionDate: dayjs.Dayjs | null = !!video.actionDate
    ? dayjs(new Date(video.actionDate))
    : null;

  return {
    ...video,
    formattedDateAdded: dateAdded.format('MM/DD/YY'),
    formattedTimeAdded: dateAdded.format('hh:mm a'),
    type: video?.linkedGig?.id > 0 ? 'Experience' : 'Clip',
    status:
      video.videoStatus === VideoStatusType.NUMBER_0
        ? 'new'
        : video.videoStatus === VideoStatusType.NUMBER_1
        ? 'approved'
        : video.videoStatus === VideoStatusType.NUMBER_2
        ? 'review'
        : 'rejected',
    formattedRejectionReason: translateVideoRejectionReason(
      video.videoRejectReason
    ),
    formattedActionDate: actionDate ? actionDate.format('MM/DD/YY') : '',
    formattedActionTime: actionDate ? actionDate.format('hh:mm a') : ''
  };
};

export const createVideoReportModel = (
  video: MediaFileReportViewModel
): IVideoReportRow => {
  const _createdOn = dayjs(new Date(video.createdOn));
  const formattedReportingDate = _createdOn.format('MM/DD/YY');
  const formattedReportingTime = _createdOn.format('hh:mm a');

  return {
    ...video,
    id: video.mediaFile.id,
    status:
      video?.isBadVideo === null
        ? 'new'
        : video.isBadVideo
        ? 'declined'
        : 'approved',
    isVideo: video.mediaFile.awsUrl.endsWith('.mp4'),
    formattedReportingDate,
    formattedReportingTime
  };
};

export const createBookingReportModel = (
  booking: BookingViewModel
): IBookingReportRow => {
  const _reportedOn = dayjs(new Date(booking.reportedOn));
  const formattedReportingDate = _reportedOn.format('MM/DD/YY');
  const formattedReportingTime = _reportedOn.format('hh:mm a');

  return {
    ...booking,
    status: booking.reportResolution === null ? 'new' : 'completed',
    formattedReportingDate,
    formattedReportingTime
  };
};

export const createExtendedUserModel = (user: UserViewModel): IUserRow => {
  const createdOn = dayjs(user.createdOnUnix);
  const deactivatedOn =
    user.deletedOnUnix > 0 ? dayjs(user.deletedOnUnix) : null;

  return {
    ...user,
    createdOn: user.createdOnUnix,
    deactivatedOn: deactivatedOn?.toDate()?.getTime() ?? 0,
    formattedCreatedOnDate: createdOn.format('MM/DD/YY'),
    formattedCreatedOnTime: createdOn.format('hh:mm a'),
    formattedDeactivatedOnDate: deactivatedOn
      ? deactivatedOn.format('MM/DD/YY')
      : '',
    formattedDeactivatedOnTime: deactivatedOn
      ? deactivatedOn.format('hh:mm a')
      : '',
    accountType: user.isAdmin ? 'admin' : 'normal',
    userType: user.isCreatorPro ? 'creator' : 'user',
    accountStatus: user.isSoftDeleted ? 'inactive' : 'active'
  };
};

export const createSearchDataFromUsers = (user: IUserRow): SearchModalData => ({
  value: user.id,
  title: user.userName,
  image: user.image
});

export const createSearchDataFromCategories = (
  category: CategoryViewModel
): SearchModalData => ({
  value: category.slug,
  title: category.name,
  image: category.image
});

export const createSearchDataFromHashtags = (
  hashtag: string
): SearchModalData => ({
  value: hashtag,
  title: hashtag,
  image: null
});

export const createFilterOptionsFromLocations = (
  locations: { city: string; state: string }[]
): FilterOptionType[] => {
  return locations.map((location) => {
    return {
      group: '',
      value: `${location.city}:${location.state}`,
      label: location.city
    };
  });
};

export const createFilterOptionsFromCategories = (
  categories: CategoryViewModel[]
): FilterOptionType[] => {
  return categories.map((category) => {
    return {
      group: '',
      value: category.id.toString(),
      label: category.name
    };
  });
};

export const createSearchDataFromSelectedHashtagsList = (
  hashtags: string[],
  banner: BannerViewModel
): SearchModalData[] => {
  if (!banner?.genericData) return [];

  const hashtagsFiltered = hashtags.filter((hashtag) =>
    banner?.genericData?.split(',').includes(hashtag)
  );
  if (hashtagsFiltered.length === 0) return [];

  return hashtagsFiltered.map((hashtag) => ({
    value: hashtag,
    title: hashtag,
    image: null
  }));
};

export const createStatsFromExperiences = (
  experiences: ExperienceLogViewModelV2[]
): IGeneralStats => {
  const creatorsStats = experiences.map<IStatsRow>((stat) => {
    return {
      ...stat,
      serviceFee: stat.sumCustomerFee,
      totalSales: stat.sumBookingTotal + stat.sumCustomerFee
    };
  });

  const globalStats = creatorsStats.reduce<IStatsRow>(
    (acc, current) => {
      acc.totalSales += current.sumBookingTotal + current.serviceFee;
      acc.totalBookings += current.totalBookings;
      acc.sumBookingTotal += current.sumBookingTotal;
      acc.sumCreatorTips += current.sumCreatorTips;
      acc.sumHooplaFee += current.sumHooplaFee;
      acc.sumTotalEarnings += current.sumTotalEarnings;
      acc.serviceFee += current.serviceFee;

      return acc;
    },
    {
      totalBookings: 0,
      sumCreatorTips: 0,
      sumHooplaFee: 0,
      sumBookingTotal: 0,
      sumTotalEarnings: 0,
      serviceFee: 0,
      totalSales: 0
    }
  );

  return { globalStats, creatorsStats };
};

export const createAccountStats = (users: IUserRow[]) => {
  return users.reduce<IAccountStats>(
    (acc, current) => {
      if (current.isCreatorPro) acc.creatorCount += 1;
      else acc.customerCount += 1;

      return acc;
    },
    {
      customerCount: 0,
      creatorCount: 0
    }
  );
};

export const getExperienceThumbnails = (files: FileCarouselItemViewModel[]) => {
  const sortedFiles = files
    .slice()
    .sort((a, b) =>
      a.awsUrl.endsWith('mp4') ? -1 : b.awsUrl.endsWith('mp4') ? 1 : 0
    );

  const file = sortedFiles[0];
  const thumbs = file?.thumbnails;

  const videoUrl = thumbs?.web[1] ? getMediaFile(thumbs?.web[1]) : null;
  const animatedImageUrl = thumbs?.web[0] ? getMediaFile(thumbs.web[0]) : null;
  const staticImageUrl = (thumbs?.mobile as any)?.static[0]
    ? getMediaFile((thumbs?.mobile as any)?.static[0])
    : file?.awsUrl
    ? getMediaFile(file.awsUrl)
    : null;

  return {
    videoUrl,
    animatedImageUrl,
    staticImageUrl
  };
};

export const getEventsThumbnails = (files: GetAllEventsOnlyMediaFile[]) => {
  const sortedFiles = files
    .slice()
    .sort((a, b) =>
      a.awsUrl.endsWith('mp4') ? -1 : b.awsUrl.endsWith('mp4') ? 1 : 0
    );

  const file = sortedFiles[0];
  const thumbs = file?.mediaThumbnail;

  const videoUrl = thumbs?.web[1] ? getMediaFile(thumbs?.web[1]) : null;
  const animatedImageUrl = thumbs?.web[0] ? getMediaFile(thumbs.web[0]) : null;
  const staticImageUrl = (thumbs?.mobile as any)?.static[0]
    ? getMediaFile((thumbs?.mobile as any)?.static[0])
    : file?.awsUrl
    ? getMediaFile(file.awsUrl)
    : null;

  return {
    videoUrl,
    animatedImageUrl,
    staticImageUrl
  };
};
