import { TableRow, TableCell } from '@mui/material';

export interface IShareStatRow {
  id: number;
  name: string;
  category: string;
  shares: number;
}

export interface IShareStatProp {
  share: IShareStatRow;
}

const ShareStatRow = ({ share }: IShareStatProp) => {
  return (
    <TableRow>
      <TableCell>{share.name}</TableCell>
      <TableCell>{share.category}</TableCell>
      <TableCell align='right'>{share.shares}</TableCell>
    </TableRow>
  );
};

export default ShareStatRow;
