import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { styled } from '@mui/material/styles';
import {
  IHighlightBannerDataOutput,
  IModalProps,
  SearchModalData
} from '../../../core/types';
import BaseModal from '../BaseModal/BaseModal';
import './NewBannerModal.scss';
import { isValidURL } from '../../../core/helpers/helpers';
import { FormikErrors, FormikValues, useFormik } from 'formik';
import { Badge, Stack } from '@mui/material';
import SearchModal from '../../SearchModal/SearchModal';
import { useAppSelector } from '../../../state';
import {
  createSearchDataFromUsers,
  createSearchDataFromCategories,
  createSearchDataFromHashtags
} from '../../../core/helpers/model-helpers';
import getGlobal from '../../../core/globals';
import { BannerType } from '../../../core/backend/models';
import HighlightFiltersBannerModal from './HighlightFiltersBanner/HighlightFiltersBannerModal';
import { slugify } from '../../../core/helpers/string-helpers';

const Input = styled('input')({
  display: 'none'
});

interface IProps extends IModalProps {
  locations: { city: string; state: string }[];
}

interface FormValues {
  image: File;
  title?: string;
  subTitle?: string;
  link: string;
  type: BannerType;
  genericData?: string;
  keyId?: string;
}

const NewBannerModal = ({ onAccept, onClose, locations, ...props }: IProps) => {
  const { users, categories, hashtags } = useAppSelector((state) => state);
  const [autoGenerateUrl, setAutoGenerateUrl] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isHashTagModalOpen, setIsHashTagModalOpen] = useState(false);
  const [isHighlightModalOpen, setIsHighlightModalOpen] = useState(false);
  const prevImageRef = useRef<HTMLImageElement>(null);

  const { errors, values, handleChange, submitForm, setFieldValue, resetForm } =
    useFormik<FormValues>({
      initialValues: {
        image: null,
        link: '',
        title: null,
        subTitle: null,
        type: BannerType.NUMBER_0,
        genericData: null,
        keyId: ''
      },
      validateOnChange: false,
      validateOnBlur: false,
      validate: (values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.image) {
          errors.image = 'Please select an image.';
        }

        if (!isValidURL(values.link)) {
          errors.link = 'Please enter a valid link.';
        }

        if (!values.title) {
          errors.title = 'Please enter a title.';
        } else if (values.title?.length > 50) {
          errors.title =
            'Oops! The title text is too long. Make sure it is no longer than 50 characters.';
        }

        if (values.subTitle?.length > 50) {
          errors.subTitle =
            'Oops! The subtitle text is too long. Make sure it is no longer than 50 characters.';
        }

        if (
          values.type === BannerType.NUMBER_3 ||
          values.type === BannerType.NUMBER_4
        ) {
          if (!values.keyId || values.keyId.trim().length === 0) {
            errors.keyId = 'Please enter a name or phrase.';
          } else if (!/^[a-zA-Z0-9\s]+$/.test(values.keyId)) {
            errors.keyId = 'Please enter only letters, numbers, and spaces.';
          }

          if (!values.genericData) {
            errors.genericData =
              values.type === BannerType.NUMBER_3
                ? 'Please select at least one hashtag.'
                : 'Please select at least one filter.';
          }
        }

        return errors;
      },
      onSubmit: async (values: FormikValues, { resetForm }) => {
        await onAccept(values);
        resetForm();
        setAutoGenerateUrl(false);
      }
    });

  useEffect(() => {
    if (!props.isOpen && prevImageRef.current) {
      prevImageRef.current = null;
    }
  }, [props.isOpen, prevImageRef.current]);

  useEffect(() => {
    if (
      values.type == BannerType.NUMBER_3 ||
      values.type == BannerType.NUMBER_4
    )
      handleChangeLink();
  }, [values.keyId, setFieldValue]);

  // handler to create hashtag/highlight banner links with a key/collection name:
  const handleChangeLink = () => {
    if (
      values.type !== BannerType.NUMBER_3 &&
      values.type !== BannerType.NUMBER_4
    )
      return;
    if (values.keyId) {
      setFieldValue(
        'link',
        `${getGlobal('appUrl')}/${
          values.type === BannerType.NUMBER_3 ? 'tags' : 'collection'
        }/${slugify(values.keyId)}`
      );
    } else {
      setFieldValue('link', `${getGlobal('appUrl')}/tags`);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const reader = new FileReader();

      setFieldValue('image', e.currentTarget.files[0]);
      reader.onload = () => {
        prevImageRef.current.src = reader.result as string;
      };

      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  };

  const handleSelectCategory = (category: SearchModalData[]) => {
    const url = `${getGlobal('appUrl')}/category/${category[0].value}`;
    setFieldValue('link', url);
    setFieldValue('type', BannerType.NUMBER_2);
    setFieldValue('genericData', null);
  };

  const handleSelectUser = (user: SearchModalData[]) => {
    const url = `${getGlobal('appUrl')}/${user[0].title}`;
    setFieldValue('link', url);
    setFieldValue('type', BannerType.NUMBER_1);
    setFieldValue('genericData', null);
  };

  const handleSelectHashtags = (hashtags: SearchModalData[]) => {
    const url = `${getGlobal('appUrl')}/tags`;
    setFieldValue('link', url);
    setFieldValue('type', BannerType.NUMBER_3);
    setFieldValue('genericData', hashtags.map((x) => x.value).join(','));
  };

  const handleSelectHighlightFilters = (
    filters: IHighlightBannerDataOutput
  ) => {
    const url = `${getGlobal('appUrl')}/collection/${values.keyId}`;
    setFieldValue('link', url);
    setFieldValue('type', BannerType.NUMBER_4);
    setFieldValue('genericData', JSON.stringify(filters));
  };

  const handleAutoGenerateUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoGenerateUrl(e.target.checked);
  };

  const handleShowCategoriesModal = () => setIsCategoryModalOpen(true);
  const handleShowUsersModal = () => setIsUserModalOpen(true);
  const handleShowHashTagsModal = () => setIsHashTagModalOpen(true);
  const handleShowHighlightModal = () => setIsHighlightModalOpen(true);

  const handleCloseCategoriesModal = () => setIsCategoryModalOpen(false);
  const handleCloseUsersModal = () => setIsUserModalOpen(false);
  const handleCloseHashTagsModal = () => setIsHashTagModalOpen(false);
  const handleCloseHighlightModal = () => setIsHighlightModalOpen(false);

  const handleOnClose = () => {
    onClose();
    resetForm();
    setAutoGenerateUrl(false);
  };

  return (
    <BaseModal
      title='Create Banner'
      onAccept={submitForm}
      onClose={handleOnClose}
      {...props}
    >
      <div className='NewBannerModal__banner'>
        <img className='NewBannerModal__bannerImage' ref={prevImageRef} />
        <label htmlFor='contained-button-file'>
          <Input
            accept='image/*'
            id='contained-button-file'
            multiple
            type='file'
            onChange={handleImageChange}
          />
          <Button
            variant='outlined'
            component='span'
            color='secondary'
            startIcon={<PhotoCamera />}
          >
            Browse Gallery
          </Button>
        </label>
      </div>

      {(errors.image || errors.genericData) && (
        <div className='NewBannerModal__imageError'>
          {errors.image ?? errors.genericData}
        </div>
      )}

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleAutoGenerateUrl}
              checked={autoGenerateUrl}
            />
          }
          label='Auto Generate URL'
        />
        {autoGenerateUrl && (
          <Stack spacing={2} direction='row'>
            <Badge
              color='secondary'
              variant='dot'
              invisible={values.type != BannerType.NUMBER_2}
            >
              <Button
                variant='outlined'
                color='secondary'
                size='small'
                startIcon={<AddLinkIcon />}
                onClick={handleShowCategoriesModal}
              >
                Category
              </Button>
            </Badge>
            <Badge
              color='secondary'
              variant='dot'
              invisible={values.type != BannerType.NUMBER_1}
            >
              <Button
                variant='outlined'
                color='secondary'
                size='small'
                startIcon={<AddLinkIcon />}
                onClick={handleShowUsersModal}
              >
                User
              </Button>
            </Badge>
            <Badge
              color='secondary'
              variant='dot'
              invisible={values.type != BannerType.NUMBER_3}
            >
              <Button
                variant='outlined'
                color='secondary'
                size='small'
                startIcon={<AddLinkIcon />}
                onClick={handleShowHashTagsModal}
              >
                HashTags
              </Button>
            </Badge>
            <Badge
              color='secondary'
              variant='dot'
              invisible={values.type != BannerType.NUMBER_4}
            >
              <Button
                variant='outlined'
                color='secondary'
                size='small'
                startIcon={<AddLinkIcon />}
                onClick={handleShowHighlightModal}
              >
                Highlight
              </Button>
            </Badge>
          </Stack>
        )}
      </FormGroup>

      <div className='NewBannerModal__formSpace'></div>

      {/* Just for category and user banner types */}
      {values.type != BannerType.NUMBER_3 &&
        values.type != BannerType.NUMBER_4 && (
          <TextField
            id='link'
            name='link'
            label='Enter a link for this banner'
            variant='standard'
            value={values.link}
            onChange={handleChange}
            error={!!errors.link}
            helperText={errors.link ?? ''}
            fullWidth
          />
        )}

      {/* Just for hashtag and highlight banner types */}
      {(values.type == BannerType.NUMBER_3 ||
        values.type == BannerType.NUMBER_4) && (
        <>
          <TextField
            id='link'
            name='link'
            label='This link will be generated once you press on the "confirm" button.'
            variant='standard'
            value={values.link}
            onChange={handleChange}
            error={!!errors.link}
            helperText={errors.link ?? ''}
            fullWidth
            disabled
          />
          <TextField
            id='keyId'
            name='keyId'
            label="Enter a name or phrase (it will be included in the url in 'slug' format)"
            variant='standard'
            value={values.keyId}
            onChange={(e) => {
              const formattedValue = e.target.value.toLowerCase();
              setFieldValue('keyId', formattedValue);
            }}
            error={!!errors.keyId}
            helperText={errors.keyId ?? ''}
            fullWidth
            margin='normal'
          />
        </>
      )}

      <TextField
        id='title'
        name='title'
        label='Enter a title...'
        variant='standard'
        value={values.title}
        onChange={handleChange}
        error={!!errors.title}
        helperText={errors.title ?? ''}
        fullWidth
        margin='normal'
      />
      <TextField
        id='subTitle'
        name='subTitle'
        label='Enter a subtitle (optional)...'
        variant='standard'
        value={values.subTitle}
        onChange={handleChange}
        error={!!errors.subTitle}
        helperText={errors.subTitle ?? ''}
        fullWidth
        margin='normal'
      />

      <SearchModal
        title='Select Category'
        open={isCategoryModalOpen}
        data={categories.map(createSearchDataFromCategories)}
        dataLimit={50}
        onSelect={handleSelectCategory}
        onClose={handleCloseCategoriesModal}
      />

      <SearchModal
        title='Select User'
        open={isUserModalOpen}
        data={users.map(createSearchDataFromUsers)}
        onSelect={handleSelectUser}
        onClose={handleCloseUsersModal}
      />

      <SearchModal
        title='Select Hashtags'
        isMultipleSelection
        multipleSelectionButtonSelectText='Select Hashtags'
        itemLetter='#'
        open={isHashTagModalOpen}
        data={hashtags.map(createSearchDataFromHashtags)}
        dataLimit={hashtags.length}
        onSelect={handleSelectHashtags}
        onClose={handleCloseHashTagsModal}
      />

      <HighlightFiltersBannerModal
        categories={categories}
        locations={locations}
        open={isHighlightModalOpen}
        onSelect={handleSelectHighlightFilters}
        onClose={handleCloseHighlightModal}
      />
    </BaseModal>
  );
};

export default NewBannerModal;
