import { Card, CardContent, Typography, Box } from '@mui/material';

interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ReactNode;
  trend?: {
    value: number;
    isPositive: boolean;
  };
}

export const StatCard = ({ title, value, icon, trend }: StatCardProps) => {
  return (
    <Card>
      <CardContent>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb={2}
        >
          <Box minHeight={32}>{icon}</Box>
          {trend && (
            <Typography
              variant='h6'
              color={trend.isPositive ? 'success.main' : 'error.main'}
              sx={{ display: 'flex', alignItems: 'center' }}
              fontWeight='bold'
            >
              {trend.isPositive ? '+' : '-'}
              {Math.abs(trend.value)}%
            </Typography>
          )}
        </Box>
        <Typography variant='h4' component='div' fontWeight='bold'>
          {value}
        </Typography>
        <Typography color='text.secondary' sx={{ mt: 1 }}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};
