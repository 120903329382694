import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FilterOptionType,
  IHighlightBannerDataOutput,
  IHighlightBannerLocation
} from '../../../../core/types';
import { useState } from 'react';
import { Alert } from '@mui/material';
import Filter from '../../../Filter/Filter';
import {
  createFilterOptionsFromCategories,
  createFilterOptionsFromLocations
} from '../../../../core/helpers/model-helpers';
import { CategoryViewModel } from '../../../../core/backend/models';

interface Props {
  open: boolean;
  bannerId?: number;
  dataLimit?: number;
  locations: { city: string; state: string }[];
  categories: CategoryViewModel[];
  onSelect?: (item: IHighlightBannerDataOutput) => void;
  onClose: (e?: React.MouseEvent) => void;
}

const HighlightFiltersBannerModal = ({
  open,
  locations,
  categories,
  onSelect,
  onClose
}: Props) => {
  const [selectedCity, setSelectedCity] =
    useState<IHighlightBannerLocation>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<FilterOptionType>(null);

  const selectFilters = () => {
    const data: IHighlightBannerDataOutput = {
      location: selectedCity ?? null,
      categoryId: selectedCategory ? parseInt(selectedCategory?.value) : 0
    };

    onSelect(data);
    handleClose();
  };

  const handleClose = () => {
    setSelectedCategory(null);
    setSelectedCity(null);
    onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      maxWidth='xs'
      fullWidth={true}
    >
      <DialogTitle>Create / Edit your filters</DialogTitle>
      <DialogContent>
        <Box component='form' sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Alert severity='info'>
            Here you can create or edit your filters, based on an event's city
            or category, or both if you want.
          </Alert>

          <p>Select your city</p>
          <Filter
            options={createFilterOptionsFromLocations(locations)}
            multiple={false}
            placeholder='Filter by city name...'
            onChange={(city: FilterOptionType[]) => {
              if (!city) {
                setSelectedCity(null);
                return;
              }

              const newCity: IHighlightBannerLocation = {
                city: city[0].label,
                state: city[0].value.split(':')[1]
              };
              setSelectedCity(newCity);
            }}
          />

          <p>Select your category</p>
          <Filter
            options={createFilterOptionsFromCategories(categories)}
            multiple={false}
            placeholder='Filter by category name...'
            onChange={(category: FilterOptionType[]) => {
              setSelectedCategory(category ? category[0] : null);
            }}
          />

          {selectedCity && (
            <div
              style={{ flex: 1, flexDirection: 'column', marginTop: '20px' }}
            >
              <p style={{ fontWeight: 'bold' }}>Filter statement:</p>
              <p style={{ fontStyle: 'italic' }}>
                {`"Get the events located in ${selectedCity?.city} 
                ${
                  selectedCategory
                    ? ` and that are of category '${selectedCategory?.label}'"`
                    : '"'
                }`}
              </p>
            </div>
          )}

          {selectedCategory && !selectedCity && (
            <div
              style={{ flex: 1, flexDirection: 'column', marginTop: '20px' }}
            >
              <p style={{ fontWeight: 'bold' }}>Filter statement:</p>
              <p>{`Get the events that are of category '${selectedCategory.label}'`}</p>
            </div>
          )}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px 16px' }}>
        <Button onClick={handleClose} color='secondary'>
          Close
        </Button>
        {(selectedCity || selectedCategory) && (
          <Button
            variant='contained'
            onClick={selectFilters}
            color='secondary'
            style={{ marginLeft: '32px' }}
          >
            Select filters
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default HighlightFiltersBannerModal;
