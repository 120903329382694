import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { getMediaFile } from '../../../core/api';
import './BannerRow.scss';
import BaseRow, { IBaseRowProps } from '../BaseRow/BaseRow';
import { BannerViewModel } from '../../../core/backend/models';
import CopyBtn from '../../CopyBtn/CopyBtn';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

interface IProps extends IBaseRowProps {
  banner: BannerViewModel;
  sortMaxLength: number;
  onSelect: React.MouseEventHandler;
  onSortChange: React.ChangeEventHandler;
  onEdit: React.MouseEventHandler;
  onDelete: React.MouseEventHandler;
}

const BannerRow = ({
  banner,
  sortMaxLength,
  selected,
  onSelect,
  onSortChange,
  onEdit,
  onDelete
}: IProps) => {
  return (
    <BaseRow selected={selected}>
      <TableCell padding='checkbox'>
        <Checkbox color='primary' checked={selected} onClick={onSelect} />
      </TableCell>
      <TableCell scope='row' sx={{ width: '220px' }}>
        <img
          alt=''
          src={getMediaFile(banner.mediaFile.awsUrl)}
          width='300'
          height='150'
        />
      </TableCell>
      <TableCell sx={{ maxWidth: '350px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              fontWeight: 'bold',
              color: '#6950fb',
              fontSize: '14px',
              backgroundColor: '#f4f3ff',
              padding: '4px 10px 2px',
              borderRadius: '6px'
            }}
          >
            {banner.link}
          </div>
          <div className='BannerRow__copy-button'>
            <CopyBtn
              textToCopy={banner.link}
              successMsg='Banner URL link copied to the clipboard!'
            />
          </div>
        </div>
      </TableCell>

      <TableCell sx={{ maxWidth: '50px' }}>
        <TextField
          id='banner-sort'
          select
          label='Sort'
          value={banner.order}
          onChange={onSortChange}
          variant='standard'
        >
          {Array.from({ length: sortMaxLength }, (_, index) => index).map(
            (option) => (
              <MenuItem key={option} value={option}>
                {option + 1}
              </MenuItem>
            )
          )}
        </TextField>
      </TableCell>

      <TableCell align='center'>
        <Grid container justifyContent='flex-start'>
          <Grid item md={12} lg={6}>
            <Button variant='text' color='primary' onClick={onEdit}>
              <EditIcon /> Edit
            </Button>
          </Grid>
          <Grid item md={12} lg={6}>
            <Button variant='text' color='error' onClick={onDelete}>
              <ClearIcon /> Delete
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    </BaseRow>
  );
};

export default BannerRow;
