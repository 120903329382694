import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Cms.scss';
import { NavLink, Route, Switch } from 'react-router-dom';
import ModerateVideos from '../ModerateVideos/ModerateVideos';
import ModerateComments from '../ModerateComments/ModerateComments';
import { RoutesEnum } from '../../core/enums';
import Banners from '../Banners/Banners';
import Categories from '../Categories/Categories';
import HomePageCms from '../HomePageCms/HomePageCms';

const Cms = () => {
  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& > *': {
            mb: 3
          }
        }}
      >
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.Banners}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Banners
          </Button>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.Categories}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Categories
          </Button>
          <Button
            color='secondary'
            component={NavLink}
            to={RoutesEnum.HomePage}
            sx={{
              '&.active': {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.secondary.dark
              }
            }}
            exact
          >
            Homepage
          </Button>
        </ButtonGroup>
      </Box>
      <Switch>
        <Route exact path={RoutesEnum.Banners} component={Banners} />
        <Route exact path={RoutesEnum.Categories} component={Categories} />
        <Route exact path={RoutesEnum.HomePage} component={HomePageCms} />
      </Switch>
    </MainLayout>
  );
};

export default Cms;
