import { useEffect, useState } from 'react';
import ShareStatRow, {
  IShareStatRow
} from '../../../components/TableRows/ShareStatRow/ShareStatRow';
import {
  Box,
  Container,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { StatCard } from '../../../components/Cards/StatCard/StatCard';
import { TrendingUp } from '@mui/icons-material';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ShareIcon from '@mui/icons-material/Share';
import BasicTable from '../../../components/Tables/BasicTable/BasicTable';
import Header from '../../../components/Header/Header';
import {
  getEventShareStats,
  getMainStats,
  getMediaShareStats
} from '../../../core/api';
import {
  GetEventShareStatsResponse,
  GetMediaShareStatsResponse,
  GetShareMainStatsResponse
} from '../../../core/backend/models';
import { DEFAULT_ERROR_MESSAGE } from '../../../core/consts';
import appToast from '../../../core/toast';
import Loader from '../../../components/Loader/Loader';

const SharingEventHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Event</TableCell>
        <TableCell>Category</TableCell>
        <TableCell align='right'>Shares</TableCell>
      </TableRow>
    </TableHead>
  );
};

const SharingMediaHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Media</TableCell>
        <TableCell>Category</TableCell>
        <TableCell align='right'>Shares</TableCell>
      </TableRow>
    </TableHead>
  );
};

const SharingStatistics = () => {
  const [eventReportPage, setEventReportPage] = useState(0);
  const [mediaReportPage, setMediaReportPage] = useState(0);
  const [mainStats, setMainStats] = useState<GetShareMainStatsResponse>(null);
  const [eventShareStats, setEventShareStats] = useState<
    GetEventShareStatsResponse[]
  >([]);
  const [mediafileShareStats, setMediafileShareStats] = useState<
    GetMediaShareStatsResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getShareStatistics();
  }, []);

  const getShareStatistics = async () => {
    try {
      setIsLoading(true);

      const _mainStats = await getMainStats();
      const _mediafileShareStats = await getMediaShareStats();
      const _eventShareStats = await getEventShareStats();

      setMainStats(_mainStats);
      setEventShareStats(_eventShareStats);
      setMediafileShareStats(_mediafileShareStats);
    } catch (e: any) {
      const error = e.response?.date?.description ?? DEFAULT_ERROR_MESSAGE;
      appToast.showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  const MapEventStatsToRows: (
    data: GetEventShareStatsResponse[]
  ) => IShareStatRow[] = (data) => {
    return data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        category: item.category,
        shares: item.total
      };
    });
  };

  const MapMediaStatsToRows: (
    data: GetMediaShareStatsResponse[]
  ) => IShareStatRow[] = (data) => {
    return data.map((item) => {
      return {
        id: item.id,
        name: item.description,
        category: item.category,
        shares: item.total
      };
    });
  };

  return (
    <Box mt={4}>
      <Header />
      <div className='Statistic'>
        <Container maxWidth='lg'>
          <Typography
            variant='h4'
            gutterBottom
            sx={{ letterSpacing: '-0.05em' }}
            color='secondary'
          >
            Main stats
          </Typography>

          {mainStats && (
            <Grid container spacing={3}>
              {/* Stat Cards */}
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title='Total Shares'
                  value={mainStats.totalShares}
                  icon={<ShareIcon htmlColor='#b83af3' />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title='Media Shares'
                  value={mainStats.mediaFileShares}
                  icon={<AccountCircleOutlinedIcon htmlColor='#b83af3' />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title='Event Shares'
                  value={mainStats.eventShares}
                  icon={<QueryStatsOutlinedIcon htmlColor='#b83af3' />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title='Growth (Last month)'
                  value={`${mainStats.lastMonthGrowth}`}
                  icon={<TrendingUp htmlColor='#b83af3' />}
                  trend={{
                    value: mainStats.lastMonthGrowthPercentage,
                    isPositive: mainStats.lastMonthGrowthPercentage >= 0
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Typography
            variant='h4'
            gutterBottom
            sx={{ letterSpacing: '-0.05em' }}
            mt={4}
            color='secondary'
          >
            Event shares
          </Typography>

          {eventShareStats && (
            <BasicTable<IShareStatRow, number>
              Header={SharingEventHeader}
              data={MapEventStatsToRows(eventShareStats)}
              idFromItem={(item) => item.id}
              page={eventReportPage}
              rowsPerPage={5}
              onPageChange={(_, newPage) => setEventReportPage(newPage)}
              // selectedRows={selectedItems}
              // onSelectionChange={handleSelectionChange}
              renderHeader={(_) => <SharingEventHeader />}
              renderItem={(share, index) => (
                <ShareStatRow share={share} key={index} />
              )}
            />
          )}

          <Typography
            variant='h4'
            gutterBottom
            sx={{ letterSpacing: '-0.05em' }}
            mt={4}
            color='secondary'
          >
            Media shares
          </Typography>

          {
            <BasicTable<IShareStatRow, number>
              Header={SharingMediaHeader}
              data={MapMediaStatsToRows(mediafileShareStats)}
              idFromItem={(item) => item.id}
              page={mediaReportPage}
              rowsPerPage={5}
              onPageChange={(_, newPage) => setMediaReportPage(newPage)}
              renderHeader={(_) => <SharingMediaHeader />}
              renderItem={(share, index) => (
                <ShareStatRow share={share} key={index} />
              )}
            />
          }
        </Container>
      </div>
    </Box>
  );
};

export default SharingStatistics;
