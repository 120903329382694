import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterOptionType } from '../../core/types';
import './Filter.scss';
interface IProps {
  options: FilterOptionType[];
  multiple?: boolean;
  placeholder?: string;
  onChange: (value: FilterOptionType[] | null) => void;
}

const Filter = ({
  options,
  onChange,
  multiple = true,
  placeholder = 'Filter'
}: IProps) => {
  return (
    <div className='Filter'>
      <FilterListIcon className='Filter__button' />
      <div className='Filter__title'>
        <Autocomplete
          id='filter'
          multiple={multiple}
          options={options}
          groupBy={(option) => option.group}
          getOptionLabel={(option) => (option as FilterOptionType).label}
          onChange={(event: any, newValue: any | null) => {
            if (!multiple && newValue) {
              onChange([newValue] as FilterOptionType[]);
            } else {
              onChange(newValue as FilterOptionType[] | null);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={placeholder}
              sx={{ border: 'none' }}
              variant='standard'
              InputProps={{
                ...params.InputProps
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Filter;
